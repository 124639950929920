import React from 'react'

import { Link } from 'gatsby'

import Layout from '../components/layout'

import { SEO } from "../components/seo"

import hmSplash from "../images/hmSplash.jpg"
import hmFirst from "../images/hm-1.jpg"
import hmSecond from "../images/hm-2.jpg"
import hmThird from "../images/hm-3.jpg"

const hm = () => {
  return (
    <div>
         <Layout>
              <div className="container page-width-medium center projects">
                 
                  <img src={hmSplash} alt="Hartt Media" />
                  <h2 class="mt50">Hartt Media </h2>
                  <h3 className='subtitle'>A Premium LA Shopify Expert Agency</h3>
                  <small class="center">Year: 2021</small>
                  <p className='mt20'>I have been lucky to work in partnership with Hartt Media for many years, and together we developed and worked on over a hundred Shopify websites.</p>
                  <p>The site displays a small fraction of those, as well as the info and the hiring process.</p>
                  <p>From Shopify small tasks, to complete custom theme development, whether on Shopify Basic or Shopify Plus plans, Hartt Media worked on it all.</p>
                  <p>For the company website, I designed more than a few variations of the layout. We are all keen on simple and clean design, with a user in mind.</p>
                  <p>For the development stack, I have used SASS, HTML and PHP, which I have recently discarded in favor of React.</p>
                  <a href="https://harttcommerce.com/" target="_blank" className='btn mt20'>Visit the site</a>  
                 
              </div>       

              <img className='mt50 project-img' src={hmFirst} alt="Hartt Media" />
              <img className='mt50 project-img' src={hmSecond} alt="Hartt Media" />
              <img className='mt50 project-img' src={hmThird} alt="Hartt Media" />

         </Layout>
    </div>
  )
  }

  export default hm
  export const Head = () => (
    <SEO />
  )
  
  